import * as React from "react"
import PropTypes from "prop-types"
// import { Link } from "gatsby"
import * as styles from "../styles/index.module.css"
// import { ImageList, ImageListItem } from "@material-ui/core"

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import BannerExample from "../components/carrousel";

import { Helmet } from "react-helmet";
import { styled } from "@material-ui/core";

const Elemento = (props) => (
  <div>
 <Helmet>
        <script src="./assets/vendor/preline/dist/hs-ui.bundle.js" />
  </Helmet>
        <div className={styles.gridQS} >
           <h1 className={styles.titleQS} >{props.item.title}{props.item.text} </h1>
        </div>
        <div style={{
          display: "flex",
          justifyContent: "center",
          textAlign: "justify"
        }}>
            <p style={{fontSize: "24px", width: "80%", maxWidth: "fit-content"}}>{props.item.description}</p>

        </div>
        <div>
               <BannerExample images={props.images} galeryStyleCarrusel={props.galeryStyleCarrusel}></BannerExample> 
        </div>
  </div>
)

Elemento.propTypes = {
    props: PropTypes.string,
}

Elemento.defaultProps = {
    props: ``,
}

export default Elemento
