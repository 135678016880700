// import React from "react"

// // import * as styles from "../components/index.module.css"
// // import Link from 'gatsby-link'
// import Layout from "../components/layout"

// import { Helmet } from "react-helmet";
// // import { styled } from "@material-ui/core";
// // import Box from '@material-ui/core/Box';
// // import Paper from '@material-ui/core/Paper';
// // import Grid from '@material-ui/core/Grid';
// import { items } from "../interfaces/home-data"
// import Elemento from "../components/elemento"
// import Categorias from "../components/categorias"
// // import BannerExample from "../components/carrousel";

// let elemento = null
// let categoriasElem = []
// let images = []
// let styleGalleryCarrusel = null
// class Noticia extends React.Component {
// // class Noticia extends React.Component {
// // function Noticia() {
//   constructor(props)
//   {
//      super(props);

  
//   //   //const [elem, setElemento] = useState(elemento)
//     function busqueda(param) {
//       for (let item of items) {
//         let elem = item.find(x => x.id === param)
//         if (elem) return elem
//       }
//       return null
//     }

//     function duplicado(array, text) {
//       for (let item of array) {
//         // console.log(item)
//         //   let elem = item.text === text;
//         if (item.text === text) return true
//       }
//       return false
//     }

//     function categorias(elem) {
//       if (elem) {
//         for (let item of items) {
//           let elemen = item.map(x => {
//             let existe = x.categoria.includes(elem.categoria[0])
//             let dup = false
//             dup = duplicado(categoriasElem, x.text)
//             if (existe && x.id !== elem.id && x.text !== elem.text && !dup) {
//               categoriasElem.push(x)
//               //   console.log(x)
//             }
//           })
//         }
//       }
//     }

//     elemento = null

//     categoriasElem = []
//     //  const { id } = window.location.pathname;
//     // let param =  parseInt(window.location.search.split("=")[1]);
//     let parametros = props.location.search.split("=")[1]
//     if (parametros) {
//       let param = parseInt(props.location.search.split("=")[1].split("?")[0])
//       this.styleGalleryCarrusel = props.location.search.split("=")[2];
//       // console.log(galeryStyleCarrusel)

//       elemento = busqueda(param)
//       if (elemento) {
//         images = elemento.imgsData
//       }
//       categorias(elemento)
//       elemento = busqueda(param)
 
//       const [elem, setElemento] = useState(elemento)
//       //  categoriasElem=[];
//       this.setState(setElemento(elemento))
//       categorias(elemento)
//       console.log(elemento)
//       console.log(categoriasElem)
//       if (elemento) {
//         images = elemento.imgsData
//       }
// }
//     //}

//   //   //  console.log(elemento)
//   //   //  console.log(categoriasElem)
//   //   this.setState(setElemento(elemento))
//   // }

//   // const Noticia = (props) => (

//   //    componentWillReceiveProps(){
//   // shouldComponentUpdate() {
//     // super(this.props)
//     // elemento = null

//     // categoriasElem = []
//     // images = []
//     // let param = parseInt(this.props.location.search.split("=")[1].split("?")[0])
//     // this.styleGalleryCarrusel = this.props.location.search.split("=")[2]
//     // console.log(param)
//     // function busqueda(param) {
//     //   for (let item of items) {
//     //     let elem = item.find(x => x.id === param)
//     //     if (elem) return elem
//     //   }
//     //   return null
//     // }
//     // function duplicado(array, text) {
//     //   for (let item of array) {
//     //     // console.log(item)
//     //     //   let elem = item.text === text;
//     //     if (item.text === text) return true
//     //   }
//     //   return false
//     // }
//     // function categorias(elem) {
//     //   if (elem) {
//     //     for (let item of items) {
//     //       let elemen = item.map(x => {
//     //         let existe = x.categoria.includes(elem.categoria[0])
//     //         let dup = false
//     //         dup = duplicado(categoriasElem, x.text)
//     //         if (existe && x.id !== elem.id && x.text !== elem.text && !dup) {
//     //           categoriasElem.push(x)
//     //           //   console.log(x)
//     //         }
//     //       })
//     //     }
//     //   }
//     // }

//     //  elemento = null;
 
 

//     //  render();
//   //  return true
// //  }

//    render() { 
//     return elem ? (
//       <Layout>
//          <Helmet>
//             <script src="./assets/vendor/preline/dist/hs-ui.bundle.js" />
//           </Helmet>
//         <section id="Noticia">
//           <Elemento
//             item={elem}
//             images={images}
//             galeryStyleCarrusel={true}
//           ></Elemento>{" "}
//           {/*categorias={categoriasElem} */}
//           <hr style={{ margin: "30px 4%" }} />
//           <Categorias item={elem} categorias={categoriasElem}></Categorias>
//           {/* <BannerExample categorias={categoriasElem}></BannerExample> */}
//         </section>
//       </Layout>
//     ) }
//     // : (
//     //   <Layout>
//     //      <Helmet>
//     //         <script src="./assets/vendor/preline/dist/hs-ui.bundle.js" />
//     //     </Helmet>
//     //     <section id="Noticia">
//     //       {/* <Elemento item={elemento}></Elemento> */}
//     //       <p>No se encontro contenido para mostrar.</p>
//     //     </section>
//     //   </Layout>
//     // )
//  // }
// // }
// // )

// // export default Noticia


import React from "react"

// import * as styles from "../components/index.module.css"
// import Link from 'gatsby-link'
import Layout from "../components/layout"

// import { styled } from "@material-ui/core";
// import Box from '@material-ui/core/Box';
// import Paper from '@material-ui/core/Paper';
// import Grid from '@material-ui/core/Grid';
import { items } from "../interfaces/home-data"
import Elemento from "../components/elemento"
// import Categorias from "../components/categorias"
// import BannerExample from "../components/carrousel";
// import * as React from "react"
// import PropTypes from "prop-types"
// import { Link } from "gatsby"
import * as styles from "../styles/index.module.css"
// import { ImageList, ImageListItem } from "@material-ui/core"
// import { navigate } from "gatsby"
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import { styled } from "@material-ui/core";


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

let elemento = null
let categoriasElem = []
let images = []
let styleGalleryCarrusel = null
class Noticia extends React.Component {
  constructor(props) {
    super(props)
    function busqueda(param) {
      for (let item of items) {
        let elem = item.find(x => x.id === param)
        if (elem) return elem
      }
      return null
    }

    function duplicado(array, text) {
      for (let item of array) {
        // console.log(item)
        //   let elem = item.text === text;
        if (item.text === text) return true
      }
      return false
    }

    function categorias(elem) {
      categoriasElem = [];
      if (elem) {
        for (let item of items) {
          let elemen = item.map(x => {
            let existe = x.categoria.includes(elem.categoria[0])
            let dup = false
            dup = duplicado(categoriasElem, x.text)
            if (existe && x.id !== elem.id && x.text !== elem.text && !dup) {
              categoriasElem.push(x)
              //   console.log(x)
            }
          })
        }
      }
      return categoriasElem;
    }

    elemento = null

    categoriasElem = []
    //  const { id } = window.location.pathname;
    // let param =  parseInt(window.location.search.split("=")[1]);
    let parametros = props.location.search.split("=")[1]
    if (parametros) {
      let param = parseInt(props.location.search.split("=")[1].split("?")[0])
      styleGalleryCarrusel = props.location.search.split("=")[2]
      // console.log(galeryStyleCarrusel)

      elemento = busqueda(param)
      if (elemento) {
        images = elemento.imgsData
      }
      categorias(elemento)
    }


    this.state={
      elem: elemento,
      imgs: images,
      categorias: categoriasElem
    }
    //  console.log(elemento)
    //  console.log(categoriasElem)
 // }
  }
  // const Noticia = (props) => (

  //   componentWillReceiveProps(){

  //   shouldComponentUpdate(){
  //   // super(this.props)
  //   elemento = null;

  //   categoriasElem = []
  //   images = []
  //   let param = parseInt(this.props.location.search.split("=")[1].split("?")[0])
  //   this.styleGalleryCarrusel = this.props.location.search.split("=")[2]
  //   console.log(param)
  //   function busqueda(param) {
  //     for (let item of items) {
  //       let elem = item.find(x => x.id === param)
  //       if (elem) return elem
  //     }
  //     return null
  //   }
  //   function duplicado(array, text) {
  //     for (let item of array) {
  //       // console.log(item)
  //       //   let elem = item.text === text;
  //       if (item.text === text) return true
  //     }
  //     return false
  //   }
  //   function categorias(elem) {
  //     if (elem) {
  //       for (let item of items) {
  //         let elemen = item.map(x => {
  //           let existe = x.categoria.includes(elem.categoria[0])
  //           let dup = false
  //           dup = duplicado(categoriasElem, x.text)
  //           if (existe && x.id !== elem.id && x.text !== elem.text && !dup) {
  //             categoriasElem.push(x)
  //             //   console.log(x)
  //           }
  //         })
  //       }
  //     }
  //   }

  //   //  elemento = null;

  //   //  categoriasElem=[];
  //   elemento = busqueda(param)
  //   categorias(elemento)
  //   console.log(elemento)
  //   console.log(categoriasElem)
  //   if (elemento) {
  //     images = elemento.imgsData
  //   }

  //         this.setState({

  //           elem: elemento,
  //           imgs: images,
  //           categorias: categoriasElem
  //         }
  //         );

  //      render();
  //   // return true
  //       }
  //  }

  render() {
    return this.state.elem ? (
      <Layout>
        <section id="Noticia">
          <Elemento
            item={this.state.elem}
            images={this.state.imgs}
            galeryStyleCarrusel={true}
          ></Elemento>{" "}
          {/*categorias={categoriasElem} */}
          <hr style={{ margin: "30px 4%" }} />
          {/* <Categorias item={this.state.elem} categorias={this.state.categorias}></Categorias> */}

          <div className={styles.gridQS} >
           <h1 className={styles.titleQS} >También te puede interesar ...</h1>
        </div>
        <div>
       
                <Grid key="gallery" container className={styles.grid} style={{width: "80%", margin: "auto", height: "fit-content", maxHeight: "fit-content"}}  >
                  
                    {this.state.categorias.map(elemento => ( 
                                        
                            <Grid key={"item-"+elemento.id} item lg={4} md={5} sm={ 5} xs={11} >
                                <button key={"item-"+elemento.url} onClick={() => {this.setState({ elem: elemento, imgs: elemento.imgsData, categorias: () => {
                                   //categoriasElem = [];
                                  //  if (elemento) {
                                     for (let item of items) {
                                       let elemen = item.map(x => {
                                         let existe = x.categoria.includes(elemento.categoria[0])
                                         let dup = false
                                          //  dup = duplicado(categoriasElem, x.text)
                                          dup = () => {
                                            for (let cat of categoriasElem) {
                                              // console.log(item)
                                              //   let elem = item.text === text;
                                              if (cat.text === x.text) 
                                              {return true}
                                            }
                                            return false
                                          }
                                         if (existe && x.id !== elemento.id && x.text !== elemento.text && !dup) {
                                           categoriasElem.push(x)
                                           //   console.log(x)
                                         }
                                       })
                                     //}
                                      }
                                   return categoriasElem;
                                   }
                                  })
                              
                              
                              }} > 
                                {/* <button key={"item-"+elemento.url} to={elemento.url} state={{elemento: elemento}} >  */}
                                    <Item key={elemento.urlImg} className={styles.gridItem} style={{
                                        backgroundImage: `url("${elemento.urlImg}")`,
                                        maxHeight: "400px",
                                        minHeight: "100px",
                                        height: "25vw",
                                        backgroundPosition: "center",
                                        backgroundSize: "cover",
                                        backgroundRepeat: "no-repeat",
                                        display: "flex",
                                        alignItems: "flex-end",
                                        justifyContent: "flex-end",
                                        padding: "0px",
                                        borderRadius: "0px"
                                    }}>
                                        <div className={styles.divGradiente} style={{
                                            background: `linear-gradient(180deg, rgb(255,255,255,0) 0%, ${elemento.color}80 22%, ${elemento.color} 90%)`,
                                    }}>
                                            <p>
                                                <b>{elemento.title}</b> {elemento.text}
                                            </p>
                                        </div>
                                        
                                    
                                    </Item>
                                </button>
                            </Grid> 
                        ))}


                </Grid>
        </div>




          {/* <BannerExample categorias={categoriasElem}></BannerExample> */}
        </section>
      </Layout>
    ) : (
      <Layout>
        <section id="Noticia">
          {/* <Elemento item={elemento}></Elemento> */}
          <p>No se encontro contenido para mostrar.</p>
        </section>
      </Layout>
    )
  }
}
// )

export default Noticia
